<template>
  <form @submit="onSubmit">
    <div class="flex-1 overflow-y-auto">
      <ui-typography
        text="Microphone"
        variant="h4"
        class="mb-2"
      />
      <div class="relative bg-black aspect-video rounded-md lg:w-4/5 w-full">
        <common-jitsi-video-track
          v-if="localInputTrack"
          :key="localInputTrack.getId() || 'video-local-track'"
          class="rounded-md"
          :is-mirror="values.isMirror"
          :track="localInputTrack"
        />
      </div>

      <ui-fields-select
        class="mb-4"
        name="deviceId"
        :options="options"
        @select="createVideoTrack"
      />
      <ui-fields-switch
        label="Mirror my video"
        name="isMirror"
        :checked-value="true"
        :unchecked-value="false"
      />
    </div>
    <common-jitsi-modals-parts-form-footer :loading="isSubmitting" />
  </form>
</template>

<script setup lang="ts">
  import type JitsiLocalTrack from 'modules/RTC/JitsiLocalTrack';

  const meetStore = useMeetStore();
  const { onClose } = useModalState();

  const { handleSubmit, isSubmitting, values } = useForm({
    initialValues: {
      deviceId: meetStore.selectDevices.video,
      isMirror: meetStore.localVideoMirror,
      sharingFrameRate: meetStore.state.sharingFrameRate
    }
  });

  const localInputTrack = ref<JitsiLocalTrack>();

  const options = computed(() => {
    return useMap(meetStore.devicesList.video, ({ deviceId, label }) => ({
      label,
      value: deviceId
    }));
  });

  const createVideoTrack = async (deviceId: string) => {
    const JitsiMeetJS = meetStore.getJitsiMeetJS();
    if (localInputTrack.value) {
      await localInputTrack.value.dispose();
    }
    const opts: any = {
      devices: ['video'],
      cameraDeviceId: deviceId
    };
    localInputTrack.value = (await JitsiMeetJS.createLocalTracks(opts))[0];
  };

  const onSubmit = handleSubmit(async val => {
    if (val.deviceId !== meetStore.selectDevices.video) {
      await meetStore.setVideoDevice(val.deviceId);
    }

    meetStore.setMirrorVideo(val.isMirror);

    onClose();
  });

  onMounted(() => {
    createVideoTrack(meetStore.selectDevices.video);
  });

  onBeforeUnmount(() => {
    localInputTrack.value?.dispose();
    localInputTrack.value = undefined;
  });
</script>
