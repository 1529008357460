<template>
  <ui-wrap-modal
    v-bind="$attrs"
    size="xl"
  >
    <template #wrap>
      <div class="grid lg:grid-cols-[250px_1fr] grid-cols-1 max-h-full min-h-[80vh] h-[600px] overflow-hidden">
        <div
          v-show="isLg ? !activeTab : true"
          class="lg:border-r-2 py-5 max-h-full overflow-y-auto"
        >
          <div class="flex items-center gap-2 md:justify-between px-5 mb-5">
            <ui-typography
              text="Settings"
              variant="h3"
            />
            <common-headers-set-them />
          </div>
          <ui-menu-list
            :items="menuItems"
            class="flex-col w-full px-5"
            @click-nav="onSelectNav"
          />
        </div>
        <div
          v-if="activeTab"
          class="p-5 mt-5 max-h-full overflow-y-auto"
        >
          <div
            v-if="isLg"
            class="mb-5 flex flex-wrap items-center gap-2"
          >
            <p>
              <ui-button
                icon-extend
                variant="secondary-outline"
                icon-left="arrow_back"
                size="md-icon"
                @click="onSelectNav(null)"
              />
            </p>
            <ui-typography
              variant="h3"
              :text="activeTab.title"
            />
          </div>
          <component
            :is="activeTab.tag"
            class="h-full flex flex-col overflow-y-auto min-h-56"
          />
        </div>
      </div>
    </template>
  </ui-wrap-modal>
</template>

<script setup lang="ts">
  import {
    CommonJitsiModalsPartsAudioSettings,
    CommonJitsiModalsPartsVideoSettings,
    CommonJitsiModalsPartsNotifySettings,
    CommonJitsiModalsPartsVirtualBackgroundSettings
  } from '#components';

  const mapSettings = {
    audio: {
      title: 'Audio Settings',
      tag: CommonJitsiModalsPartsAudioSettings
    },
    video: {
      title: 'Video Settings',
      tag: CommonJitsiModalsPartsVideoSettings
    },
    virtualBackground: {
      title: 'VirtualBackgroundSettings',
      tag: CommonJitsiModalsPartsVirtualBackgroundSettings
    },
    notify: {
      title: 'Notification Settings',
      tag: CommonJitsiModalsPartsNotifySettings
    }
  };

  type MapSettingsK = keyof typeof mapSettings;

  type Props = {
    activeNav?: MapSettingsK;
  };
  const props = defineProps<Props>();
  const emit = defineEmits(['update:modelValue']);
  createModalState(emit);

  const activeLink = ref<MapSettingsK | null>(props.activeNav || null);
  const isLg = useIsLessThan('lg');
  const device = useDevice();
  const activeTab = computed(() => (activeLink.value ? mapSettings[activeLink.value] : null));

  const menuItems = computed(() => {
    return useMap<NavLink, NavLink>(
      [
        {
          itemKey: 'audio',
          linkProps: {
            variant: 'link',
            iconLeft: 'volume_up',
            iconExtend: true,
            text: 'Audio',
            hideSpace: true
          }
        },
        {
          itemKey: 'video',
          linkProps: {
            variant: 'link',
            iconLeft: 'videocam',
            iconExtend: true,
            text: 'Video',
            hideSpace: true
          }
        },
        {
          itemKey: 'virtualBackground',
          hidden: device.isMobileOrTablet,
          linkProps: {
            variant: 'link',
            iconLeft: 'image',
            iconExtend: true,
            text: 'Virtual backgrounds',
            hideSpace: true
          }
        },
        {
          itemKey: 'notify',
          linkProps: {
            variant: 'link',
            iconLeft: 'settings',
            iconExtend: true,
            text: 'Notifications',
            hideSpace: true
          }
        }
      ].filter(item => !item.hidden),
      item => {
        if (item.linkProps) {
          item.linkProps.active = item.itemKey === activeLink.value;
        }
        return item;
      }
    );
  });

  const onSelectNav = (item: NavLink | null) => {
    activeLink.value = item ? (item.itemKey as MapSettingsK) : null;
  };
</script>
