<template>
  <form @submit="onSubmit">
    <div class="flex-1 overflow-y-auto">
      <ui-typography
        text="Microphone"
        variant="h4"
      />
      <ui-fields-select
        class="mb-4"
        name="audioInput"
        :options="optionsInput"
        @select="createAudioTrack"
      />
      <common-jitsi-audio-level
        class="mx-4 mb-5"
        line
        multi-colors
        :level="audioLevel"
      />
      <ui-fields-switch
        filed-wrap-class="mb-5"
        label="Enable noise suppression"
        name="enabledNoise"
        :checked-value="true"
        :unchecked-value="false"
      />
      <ui-typography
        text="Audio output"
        variant="h4"
      />
      <ui-fields-select
        filed-wrap-class="mb-5"
        name="audioOutput"
        :options="optionsOutput"
      />
      <ui-button
        text="Test"
        @click="onTestAudio"
      />
      <audio
        ref="testAudioRef"
        src="/libs/jitsi/sounds/outgoingRinging.mp3"
        preload="auto"
        class="hidden"
      />
    </div>
    <common-jitsi-modals-parts-form-footer :loading="isSubmitting" />
  </form>
</template>

<script setup lang="ts">
  import type JitsiLocalTrack from 'modules/RTC/JitsiLocalTrack';

  const meetStore = useMeetStore();
  const testAudioRef = ref<HTMLAudioElement>();
  const audioLevel = ref(0);
  const { onClose } = useModalState();
  const { handleSubmit, isSubmitting, values } = useForm({
    initialValues: {
      audioOutput: meetStore.selectDevices.audioOutput,
      audioInput: meetStore.selectDevices.audioInput,
      enabledNoise: meetStore.state.enabledAudioNoise
    }
  });

  let localInputTrack: JitsiLocalTrack | undefined;

  const formatOpts = (opts: MediaDeviceInfo[]) =>
    useMap(opts, ({ deviceId, label }) => ({
      label,
      value: deviceId
    }));
  const optionsInput = computed(() => {
    return formatOpts(meetStore.devicesList.audioInput);
  });
  const optionsOutput = computed(() => {
    return formatOpts(meetStore.devicesList.audioOutput);
  });

  const onSetAudioSinkId = async (deviceId: string) => setAudioSinkId(deviceId, testAudioRef.value);

  const onTestAudio = async () => {
    if (testAudioRef.value?.sinkId !== values.audioOutput) {
      await onSetAudioSinkId(values.audioOutput);
    }
    if (testAudioRef.value) {
      testAudioRef.value.currentTime = 0;
      testAudioRef.value.play();
    }
  };

  const createAudioTrack = async (deviceId: string) => {
    const JitsiMeetJS = meetStore.getJitsiMeetJS();
    if (localInputTrack) {
      await localInputTrack.dispose();
    }
    const opts: any = {
      devices: ['audio'],
      micDeviceId: deviceId
    };
    localInputTrack = (await JitsiMeetJS.createLocalTracks(opts))[0];
    localInputTrack?.addEventListener(JitsiMeetJS.events.track.TRACK_AUDIO_LEVEL_CHANGED, (level: number) => {
      audioLevel.value = level;
    });
  };

  const onSubmit = handleSubmit(async val => {
    if (val.audioOutput !== meetStore.selectDevices.audioOutput) {
      await meetStore.setAudioOutputDevice(val.audioOutput);
    }
    if (val.audioInput !== meetStore.selectDevices.audioInput) {
      await meetStore.setAudioInputDevice(val.audioInput);
    }
    meetStore.setEnabledAudioNoise(val.enabledNoise);
    onClose();
  });

  onMounted(() => {
    createAudioTrack(meetStore.selectDevices.audioInput);
  });

  onBeforeUnmount(() => {
    localInputTrack?.dispose();
    localInputTrack = undefined;
  });
</script>
