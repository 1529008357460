<template>
  <div class="flex flex-wrap gap-5 pt-6 justify-end shrink-0">
    <ui-button
      text="Cancel"
      variant="secondary"
      @click="onClose"
    />
    <ui-button
      :loading="loading"
      text="Save"
      type="submit"
    />
  </div>
</template>

<script setup lang="ts">
  defineProps<{ loading?: boolean }>();
  const { onClose } = useModalState();
</script>
