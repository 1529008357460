<template>
  <form @submit="onSubmit">
    <div class="flex-1 overflow-y-auto">
      <ui-typography
        text="Backgrounds"
        variant="h4"
        class="mb-2"
      />
      <div class="relative bg-black aspect-video rounded-md lg:w-4/5 w-full mb-6">
        <common-jitsi-video-track
          v-if="localInputTrack"
          :key="localInputTrack.getId() || 'video-local-track'"
          class="rounded-md"
          :is-mirror="meetStore.localVideoMirror"
          :track="localInputTrack"
        />
      </div>

      <div class="grid lg:grid-cols-5 md:grid-cols-3 grid-cols-2 gap-2 5">
        <common-jitsi-modals-parts-virtual-background-select-item
          class="bg-gray-700"
          tooltip="none"
          @click="
            onSelect({
              backgroundType: VirtualBackgroundType.NONE,
              blurValue: undefined,
              virtualSource: undefined
            })
          "
        />
        <common-jitsi-modals-parts-virtual-background-select-item
          tooltip="slightBlur"
          @click="
            onSelect({
              backgroundType: VirtualBackgroundType.BLUR,
              blurValue: 8,
              virtualSource: undefined
            })
          "
        >
          <span class="bg-gray-500 blur-sm absolute inset-0" />
        </common-jitsi-modals-parts-virtual-background-select-item>
        <common-jitsi-modals-parts-virtual-background-select-item
          tooltip="blur"
          @click="
            onSelect({
              backgroundType: VirtualBackgroundType.BLUR,
              blurValue: 25,
              virtualSource: undefined
            })
          "
        >
          <span class="bg-gray-500 blur absolute inset-0" />
        </common-jitsi-modals-parts-virtual-background-select-item>
        <common-jitsi-modals-parts-virtual-background-select-item
          v-for="image in VirtualBackgroundImages"
          :key="image.id"
          :img-src="image.src"
          :tooltip="image.tooltip"
          @click="
            onSelect({
              backgroundType: VirtualBackgroundType.IMAGE,
              blurValue: undefined,
              imgUrl: image.src
            })
          "
        />
      </div>
    </div>
    <common-jitsi-modals-parts-form-footer :loading="isSubmitting" />
  </form>
</template>

<script setup lang="ts">
  import type JitsiLocalTrack from 'modules/RTC/JitsiLocalTrack';
  import { VirtualBackgroundImages, VirtualBackgroundType } from '#imports';
  const meetStore = useMeetStore();
  const { onClose } = useModalState();

  const { handleSubmit, isSubmitting, values, setValues } = useForm({
    initialValues: {
      virtualBackground: meetStore.state.virtualBackground
    }
  });

  const localInputTrack = ref<JitsiLocalTrack>();

  const setEffect = () => setJitsiVideoEffect(localInputTrack.value, values.virtualBackground);

  const onSelect = (val: SetVideoEffectOptions) => {
    setValues({
      virtualBackground: val
    });
    setEffect();
  };

  const createVideoTrack = async (deviceId: string) => {
    const JitsiMeetJS = meetStore.getJitsiMeetJS();
    if (localInputTrack.value) {
      await localInputTrack.value.dispose();
    }
    const opts: any = {
      devices: ['video'],
      cameraDeviceId: deviceId
    };
    localInputTrack.value = (await JitsiMeetJS.createLocalTracks(opts))[0];
    await setEffect();
  };

  const onSubmit = handleSubmit(async val => {
    meetStore.setBackgroundValue(val.virtualBackground);

    onClose();
  });

  onMounted(() => {
    createVideoTrack(meetStore.selectDevices.video);
  });

  onBeforeUnmount(() => {
    localInputTrack.value?.dispose();
    localInputTrack.value = undefined;
  });
</script>
