<template>
  <form @submit="onSubmit">
    <div class="flex-1 overflow-y-auto">
      <ui-typography
        text="Play sound on"
        class="mb-5"
        variant="h4"
      />
      <div class="flex flex-col gap-5">
        <ui-fields-switch
          label="Participant Left"
          name="leftUser"
          :checked-value="true"
          :unchecked-value="false"
        />
        <ui-fields-switch
          label="Participant Joined"
          name="joinedUser"
          :checked-value="true"
          :unchecked-value="false"
        />
        <ui-fields-switch
          label="Participant entered lobby"
          name="addUserToLobby"
          :checked-value="true"
          :unchecked-value="false"
        />
        <ui-fields-switch
          label="Request to speak"
          name="handUp"
          :checked-value="true"
          :unchecked-value="false"
        />
      </div>
    </div>
    <common-jitsi-modals-parts-form-footer :loading="isSubmitting" />
  </form>
</template>

<script setup lang="ts">
  const meetStore = useMeetStore();
  const { onClose } = useModalState();
  const { handleSubmit, isSubmitting } = useForm({
    initialValues: { ...meetStore.state.notificationsEnabled }
  });

  const onSubmit = handleSubmit(async val => {
    meetStore.setNotificationsEnabled(val);
    onClose();
  });
</script>
